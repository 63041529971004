.mainFooterDiv {
  height: 225px;
  background: #eaeded;
  font-family: "Calibri";
  font-size: 15px;
  line-height: 37px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 200px 0 0 0;
}
